import Button from 'components/Button';
import DayList from 'components/Program/DayList';
import Modal from 'components/Modal';
import SharePanel from 'components/SharePanel';
import Title from 'components/Typography/Title';
import {
  getBounds,
  getDistance,
  getRhumbLineBearing,
  isValidCoordinate,
} from 'geolib';
import ArrowLeft from 'Icons/ArrowLeft';
import Share from 'Icons/Share';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useFavorites from 'store/useFavorites';
import usePlaces from 'store/usePlaces';
import { getDistanceWithUnit } from 'utils/helpers';
import {
  Breadcrumb,
  Breadcrumbs,
  Content,
  DayWrapper,
  Header,
  ShareBtn,
  Wrapper,
} from './SuggestionPreview.styles';

import useBrekpoint from 'hooks/useBrekpoint';
import useRecommended from 'store/useRecommended';
import i18next from 'i18next';
import EditIcon from 'Icons/EditIcon';
import useProgram from 'store/useProgram';
import useTripForm from 'store/useTripForm';

export default function SuggestionPreview() {
  const { main_map } = useMap();
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const { programs } = useRecommended();
  const { setProgram, setSuggestedPlaces, setProgramName } = useProgram();
  const { updateDestinations } = useTripForm();
  const { cities } = usePlaces();
  const { isFavorites, toggleFavorites } = useFavorites();
  const { setPlaces } = usePlaces();
  const { t } = useTranslation();
  const naviagte = useNavigate();
  const params = useParams();
  const { isSmallDevice, heigth } = useBrekpoint();

  const id = params.id;

  const { program, title } = useMemo(() => {
    const programIndex = programs?.findIndex((program) => program.id === id);
    if (programIndex > -1) {
      return {
        program: programs[programIndex].program,
        title: programs[programIndex].title,
      };
    }
  }, [programs, id]);
  const closeModal = () => {
    setShareIsOpen(false);
  };

  const animateTo = useCallback(
    ({ longitude, latitude }) => {
      if (isValidCoordinate({ longitude, latitude })) {
        main_map?.flyTo({
          center: [longitude, latitude],
          curve: 2,
          zoom: 12,
          speed: 0.6,
        });
      }
    },
    [main_map],
  );

  const headTo = useCallback(
    (start, target) => {
      if (
        start &&
        target &&
        isValidCoordinate(start) &&
        isValidCoordinate(target)
      ) {
        const { minLat, maxLat, minLng, maxLng } = getBounds([start, target]);

        const bearing = getRhumbLineBearing(start, target);
        main_map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: isSmallDevice
              ? { left: 0, top: 0, bottom: (heigth * 80) / 100 }
              : { left: 250, top: 50, bottom: 200 },
            pitch: 80,
            bearing,
            curve: 2,
            speed: 0.5,
          },
        );
      }
    },
    [main_map, heigth, isSmallDevice],
  );

  useEffect(() => {
    if (program) {
      const firstLocation = program[0][0]?.location?.coordinates;
      const secondLocation = programs[0][1]?.location?.coordinates;
      setPlaces(program.flat());
      if (secondLocation) {
        headTo(
          {
            longitude: firstLocation[0],
            latitude: firstLocation[1],
          },
          {
            longitude: secondLocation[0],
            latitude: secondLocation[1],
          },
        );
      } else if (firstLocation) {
        animateTo({
          longitude: firstLocation[0],
          latitude: firstLocation[1],
        });
      }
    }
  }, [programs, setPlaces, headTo, animateTo, program]);

  const handleHover = (start, target) => {
    headTo(start, target);
  };

  const goBack = () => {
    naviagte(-1);
  };

  const programSummary = useMemo(() => {
    const myProgram = {};

    if (program) {
      program.forEach((day, index) => {
        const firstPlaceCords = {
          latitude: day[0]?.location.coordinates[1],
          longitude: day[0]?.location.coordinates[0],
        };
        const lastPlaceCords = {
          latitude: day.slice(-1)[0]?.location.coordinates[1],
          longitude: day.slice(-1)[0]?.location.coordinates[0],
        };

        const distance = getDistance(firstPlaceCords, lastPlaceCords) * 1.4;

        myProgram[`day${index + 1}`] = {
          region: day[0].contact?.address?.city,
          distance: getDistanceWithUnit(distance),
          dayPlaces: day,
        };
      });
    }
    return myProgram;
  }, [program]);

  const programUrl = useMemo(() => {
    if (program) {
      const programIds = program.map((day) => day.map((place) => place.id));
      const baseUrl = window.location.protocol + '//' + window.location.host;
      const idsUrl = encodeURI(JSON.stringify(programIds));
      return `${baseUrl}/shared/${idsUrl}`;
    } else {
      return '';
    }
  }, [program]);

  const handleEdit = () => {
    setProgram(program);
    setSuggestedPlaces(program.flat());
    setProgramName(title[i18next.language]);
    const destinations = [];
    program.forEach((day) => {
      const regionSlug = day[0]?.contact?.address?.city;
      const regionIndex = destinations.findIndex(
        (dest) => dest.slug === regionSlug,
      );
      if (regionIndex > -1) {
        destinations[regionIndex] = {
          ...destinations[regionIndex],
          days: destinations[regionIndex].days + 1,
        };
      } else {
        const dest = cities.filter((city) => city.slug === regionSlug)[0];
        destinations.push({
          name: dest.title,
          slug: dest.slug,
          coordinates: dest.coordinates,
          days: 1,
          title: dest.title,
        });
      }
    });
    updateDestinations(destinations);

    naviagte('/program-result');
  };

  return Object.keys(programSummary).length === 0 ? (
    <Navigate to="/*" />
  ) : (
    <Wrapper>
      <Content>
        <Header>
          <Button variant="square" onClick={goBack}>
            <ArrowLeft />
          </Button>
          <Title level="h1" size="lg">
            {title[i18next.language]}
          </Title>
          <ShareBtn onClick={() => setShareIsOpen(true)}>
            <Share />
          </ShareBtn>
        </Header>
        <Button variant="primary" size="small" onClick={handleEdit}>
          <EditIcon />
          {t('editProgram')}
        </Button>
        {programSummary &&
          Object.keys(programSummary).map((day, i) => (
            <DayWrapper key={day[0].id + i.toString()}>
              <Breadcrumbs>
                <Breadcrumb>{t(day)}</Breadcrumb>
                <Breadcrumb>{programSummary[day].distance}</Breadcrumb>
                <Breadcrumb>{programSummary[day].region}</Breadcrumb>
              </Breadcrumbs>
              <DayList
                dayIndex={i}
                toggleFavorites={toggleFavorites}
                isFavorites={isFavorites}
                items={programSummary[day]?.dayPlaces}
                handleHover={handleHover}
                withBorder={false}
                isSuggestion={true}
              />
            </DayWrapper>
          ))}
      </Content>
      <Modal isOpen={shareIsOpen} closeModal={closeModal} largePanel>
        <SharePanel link={programUrl} />
      </Modal>
    </Wrapper>
  );
}
